.Softwaresolution_container{
    background-image: linear-gradient(to left,rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(../../../public/images/applicaiton_migraiton.png);
    width: 100%;
    height: 400px;
    background-position: center;
    object-fit: cover;
    background-repeat: no-repeat;
    color: #fff;
    padding: 8rem 8%;
}


.Softwaresolution_head h2{
    font-size: 3.8rem;
    
}

.Softwaresolution_head p{
    font-size: 1.5rem;
    margin-top: 2rem;
    line-height: 2.7rem;
}



.Softwaresolution-section h4 {
    position: relative;
    padding:0;
    color:#f91942;
    line-height: 1;
    letter-spacing:0.3px;
    font-size: 34px;
    font-weight: 700;  
    text-align:center;
    text-transform:none;
    margin:30px 0;
  }
  .Softwaresolution-section h4:before {
    content: '';
    width: 60px;
    height: 3px;
    background: #f91942;
    position: absolute;
    left: 0px;
    bottom: -10px;
    right:0;  
    margin:0 auto;
  }

  .Softwaresolution-section h4 span {
    font-weight: 700;
    padding-bottom: 5px;
    color:#2f2f2f
  }

  .Softwaresolution-section p{
    color:#818181;
    font-size:16px;
    line-height:28px;
    margin: 0 8%;
  }


  .Softwaresolution_service-right-row{
    display: flex;
    column-gap: 1rem;
  
}

.Softwaresolution_service-right-row i {
    font-size: 5rem;
    color: var(--pink);
}

.Softwaresolution_service-right-row img {
    font-size: 5rem;
    width: 20%;
    height: 60px;
    filter: invert(68%) sepia(22%) saturate(5989%) hue-rotate(310deg) brightness(92%) contrast(133%);
}

.webimg{
    height: 40%;
}
.webimg2{
    height: 30%;
     
}

.webimg3{
    height: 10%;
    width: 60%;
    margin-left: 28px;
     
}



.Softwaresolution_service-right-row h5 {
    font-size: 1.6rem;
    
}



  .Softwaresolution_servicesection{
    margin-top: 3rem;
 }

.Softwaresolution_service{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-flow:dense;
    grid-gap: 1.3rem;
    align-items: center;
    overflow-x: hidden;
    padding:0 8%;
    margin-top: 9rem;
    text-align: left !important;

}


.serheading{
    margin-bottom: -70px;
}

.Softwaresolution_service-right .para{
    line-height: 1.7;
    margin: 0.8rem 0 1.8rem;
    font-size: 14px;
    
}

.Softwaresolution_img{
    max-width: 100%;
    width: 430px;
    /* height: 520px; */
} 

.Softwaresolution_webimg{
    height: 40%;
}
.Softwaresolution_webimg2{
    height: 30%;
     
}


.Softwaresolution_webimg3{
    height: 10%;
    width: 60%;
    margin-left: 28px;
     
}



.span{
    color:var(--pink);
    font-size: 18px;  
    font-weight: bold;  
}

.title{
    font-size: 30px;
    font-weight: bold;
}

.para{
    color: grey;
    font-size: 14px;
}

@media (max-width: 600px) {
    .serheading{
        margin-top: 8rem;
    }
}



@media (max-width: 730px) {
    .Softwaresolution_service{
    grid-template-columns: repeat(1, 1fr);
    }

    .Softwaresolution_service>div:nth-child(4) {

        grid-row: 3;
    }

    .Softwaresolution_service>div:nth-child(8) {

        grid-row: 7;
    }
      
    
}

@media screen and (max-width: 450px) {
    .Softwaresolution_container{
    
        padding: 8rem 8% 43rem;
    }
}