
.semmaisev .contactp{
    text-align: center;
    font-size: 1.4rem;
    color: rgb(103, 103, 103);
}

.semmaisev .contactp span{
    color: var(--pink) !important;
    cursor: pointer;
}

.semmaisev .contact{
    display: flex;
    overflow: hidden;
    margin-top: 3rem;
    align-items: center;    
    justify-content: center;
    column-gap: 3rem;
    row-gap: 2rem;
    overflow-x: hidden;
    text-align: center;
}

.semmaisev .container{
    padding: calc(2px + 1.5625vw);
    border-radius: 1rem;
    box-shadow: -1px 3px 14px -3px rgba(91, 91, 91, 0.66);
}

.semmaisev .contact-form{
    width: 430px;
    padding: 1.2% 2%;
    border-radius: 1rem;
    box-shadow: -1px 3px 14px -3px rgba(91, 91, 91, 0.66);
    text-transform: lowercase;
}

.semmaisev .contact-info{
    width: 26rem;
}


.semmaisev .con{
    margin-top: 3rem;
}

.semmaisev .contact .contact-info i{
    font-size: 3rem;
    padding-bottom: 1rem;
    color: var(--pink);
}

.contact .contact-info p{
    font-size: 1.7rem;
    font-weight: 600;
}


.semmaisev .contact .contact-info .contact-details{
    font-size: 1.6rem;
    color: grey
}

.semmaisev .phoneno_error{
    color: red;
    margin-left: 1rem;
    font-size: 12px;
}

.semmaisev .contact-form input,
.semmaisev .contact-form select,
.semmaisev .contact-form textarea{
    
    width: 100%;
	margin: .8em 0;
    padding: 2.4% ;
	border: 0;
	font-weight: 400;
	background-color: transparent;
	outline: 1px solid rgb(8, 8, 8, 0.2) ;
	
	border-radius: 2px;
}

.semmaisev .contact-form input:focus,
.contact-form textarea:focus{
    outline: 1px solid var(--purple) ;
}
.semmaisev .contact-form textarea{
    padding: 7% 2%;
    
}

.semmaisev .flex-properties{
    display: flex; 
    column-gap: 1rem;
    
}


.semmaisev .contact-form input::placeholder{
    color: gray;
    font-size: 14px;
   
}

.semmaisev .contact-form input[type="submit"]{
    background: rgba(17, 138, 178, 0.8);
    color: #fff;
    width:14rem;
    font-size: 1.7rem;
    border-radius: 5rem;
    cursor: pointer;
    /* margin: 0px 130px; */
    transition: 0.2s;
    display: flex;
    justify-content: center;
}

.semmaisev .contact-form input[type="submit"]:hover{
    background:var(--purple);
    transition: 0.4s;
}

.semmaisev .getintouch{
    text-align: center;
    font-size: 2rem;
    color: rgb(93, 92, 92);
}


.semmaisev .dialogbox p{
   background-color: black;
   width: 20%;
   color: white;
   
}
.semmaisev .toast{
    position: fixed;
    padding: 18px 12px;
    bottom: 110px;
    right: 30px;
    width: 30%;
    border-radius: 2%;
    font-size: 20px;
    background-color: var(--purple);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
   
}

.semmaisev .submitbtn{
    display: flex;
    justify-content: center;
}

@media(max-width:1013px){
    .semmaisev .contact{
        flex-direction: column;
    }

    .semmaisev .container{
        margin-bottom: 2rem;
        box-shadow: none;
    }
}

@media(max-width:526px){

    .semmaisev .toast{
      
        width: 70%;
        
       
    }
    

    .semmaisev .contact-form{
        box-shadow: none;
        
    }
    

    .semmaisev .flex-properties{
        flex-direction: column;
       
    }

    .semmaisev .contact-info{
        width: 100%;
    }

    .semmaisev .contact{
        
        column-gap: 1rem;
      
    }

}

@media(max-width:420px){
    
.semmaisev .container{
    padding: 10px 10px;
    box-shadow: none;
}

.semmaisev .contact-form{
    box-shadow: none;
    
}

.semmaisev .contact-form input,
.semmaisev .contact-form select,
.semmaisev .contact-form .phone,
.semmaisev .contact-form textarea{
    
    width: 100%;
	
}




.semmaisev .getintouch form{
    margin-left: 5rem;
}


}

@media (max-width: 404px){
    .semmaisev .contact-form input,
    .semmaisev .contact-form select,
    .semmaisev .contact-form .phone,
    .semmaisev .contact-form textarea{
    
    width: 90%;

	
}

.semmaisev .form{
    margin-left: 4rem;
}


.semmaisev .contact-form input[type="submit"]{
    
    padding: 10px 32px;
  
}

.semmaisev .contact-form input[type="submit"]{
    
    display: flex;
    justify-content: center;
  
}

}

@media(max-width:382px){
    
.semmaisev .contact-form input[type="submit"]{
    
    display: flex;
    justify-content: center;
  
}


}

@media (max-width:364px){

    .semmaisev .contact-form input,
    .semmaisev .contact-form select,
    .semmaisev .contact-form .phone,
    .semmaisev .contact-form textarea{
    
    width: 90%;

}

    .semmaisev .form{
        margin-left: 4rem;
    }

    .semmaisev .contact-form input[type="submit"]{
    
        padding: 10px 28px;
      
    }


}

@media (max-width:324px){
  
    .semmaisev .contact-form{
        box-shadow: none;
        
    }
    

    .semmaisev .contact-form input,
    .semmaisev .contact-form .phone,
    .semmaisev .contact-form select,
    .semmaisev .contact-form textarea{
        
        width: 90%;
        
    }
    .semmaisev .form{
        margin-left: 4srem;
    }

    

}
