.blogview-container{
    margin: 13rem 6%;
}

.blogview-header{
    display: flex;
    align-items: center;
    column-gap: 9px;
    font-size: 2rem;
    cursor: pointer;
   
}

.blogview-title {
    margin-top: 1rem;
    font-size: 2rem;
    color: var(--pink);
}

.blogview-img {
    
    margin-top: 2rem;
    display: flex;
    justify-content: center;
}

.blogview-img img{
    width: 50%;
}

.blogview-content .description{
    font-size: 1.8rem;
    color: rgb(110, 110, 110);
    margin-top: 8px;
   
}

.blogview-content h2{
    color: var(--pink);
    margin-top: 9px;
}

.blogview-content ul, ol{
    margin-left: 6rem;
    margin-top: 6px;
}

.blogview-content p:first-child{
    margin-top: 4rem !important;
}

.related-products h3{
    text-align: center;
    font-size: 3rem;
    color: var(--pink);
   
}

.blogviewcontainer{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem 2rem;
    flex-wrap: wrap;
}