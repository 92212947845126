

:root{
  --purple:#118AB2;
  --pink: #EF476F;
  --gradient:linear-gradient(90deg, var(--purple), var(--pink));
  
}

*{
  font-family: 'Poppins', sans-serif;
  margin:0; padding:0;
  box-sizing: border-box;
  text-decoration: none;
  outline: none; border:none;
  text-transform: none;
}

*::selection{
  background:var(--purple);
  color:#fff;
}

html{
  font-size: 62.5%;
  overflow-x: hidden;
  scroll-behavior: smooth;

}

body{
  background:#f9f9f9;
}

.heading{
  text-align: center;
  background:var(--gradient);
  color:transparent;
  -webkit-background-clip: text;
  background-clip: text;
  font-size: 3.5rem;
  padding:1rem;
}


@media (max-width:1200px){
    
  html{
      font-size: 55%;
  }

}
