
.footer{
    margin-top: 3rem;
    padding-top: 1rem;
    overflow-x: hidden;
    background-color: rgb(28,31,47);
}

.footer .box-container{
    display: flex;
    flex-wrap: wrap;
   
}


.footer .box-container .box img {
    width: 80%;
    margin-top: 8rem;
}

.footer .box-container .box:first-child{
    flex: 1 1 25rem;
    margin: 2rem;
}

.footer .box-container .box:not(:first-child){
    flex: 1 1 15rem;
    margin: 2rem;
   
}


.footer .box-container .box:last-child{
    flex: 1 1 40rem;
    margin: 2rem;
    
}

.footer .box-container .box h3{
    font-size: 2.5rem;
    padding:2px 0;
    color:#fff;
    text-decoration: underline;
    text-underline-offset: 1rem;
}

.footer .box-container .box p{
    font-size: 1.5rem;
    padding:.2rem 0;
    color:#eee;
    /* text-transform: lowercase; */
}

.footer .box-container .box a{
    display: block;
    font-size: 1.5rem;
    padding:.4rem 0;
    color:#eee;
}

.footer .box-container .box a:hover{
    text-decoration: underline;
}

.footer .box-container .box .info{
    display: flex;
    align-items: center;
    /* flex-wrap: wrap; */
    text-wrap: wrap;
}

.footer .box-container .box .info p{
    word-wrap: break-word;
}

.footer .box-container .box .info i{
    margin:.4rem 0;
    margin-right: 1rem;
    border-radius: 50%;
    background:#fff;
    color:var(--pink);
    font-size: 1.5rem;
    height:4.2rem;
    min-width: 4.2rem;
    width:4.2rem;
    line-height: 4.5rem;
    text-align: center;
}

.footer .credit{
    font-size: 14px;
    font-weight: normal;
    letter-spacing: .1rem;
    color:#fff;
    border-top: .1rem solid #fff5;
    padding:2.5rem 1rem;
    text-align: center;
}



@media (max-width:600px){
    .footer .box-container{
        display: block;
        
    }

} 

.flex{
    display: flex;
    column-gap: 8px;
}