
.page_404{
    padding:10px 0;
    text-align: center;  
}

.page_404 img{ 
    width:100%;
}

.four_zero_four_bg{
 
    background-image: url("../../../public/images/pagenotfound.gif");
    height: 500px;
    background-position: center;
 }
 
 
 .four_zero_four_bg h1{
    font-size:47px;
 }
 

			 
.link_404{			 
	color: #fff!important;
    padding: 10px 20px;
    background: #39ac31;
    margin: 20px 0;
    display: inline-block;
    font-weight: bold;
    font-size: 14px;
    border-radius: 8px;
}

.contant_box_404{ 
    margin-top:-100px;
}

.contant_box_404 h3{
    font-size: 30px;
}

.contant_box_404 p{
    font-size: 15px;
}


@media screen and (max-width: 600px) {

    .four_zero_four_bg h1{
        font-size:34px;
     }
    
}