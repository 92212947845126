.clients_section{
    margin-top: 3rem;
}


.swiper-button-next{
	color: black;
}

.products{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    margin: 2rem 7rem;
    
}


.clients-img{
    height: 5rem;
    margin-left: 4rem;
}

.slidersection{
	display: flex;
	justify-content: center;
	margin: 0 8%;
}

.testimonials-section{
	width: 100%;
	/* margin: 0px 9%; */
}
.testimonials-section .section-header{
	max-width: 700px;
	text-align: center;
	margin: 30px auto 40px;
}
.testimonials-section .section-header h1{
	position: relative;
	font-size: 36px;
	color: var(--pink);
}
.testimonials-section .testimonials-container{
	position: relative;
}
.testimonials-container .testimonial-card{
	padding: 20px;
}
.testimonials-section .testimonial-card .test-card-body{
	background-color: var(--card-clr);
	box-shadow: 2px 2px 20px rgba(0,0,0,0.12);
	padding: 20px;
}
.testimonials-section .test-card-body .quote{
	display: flex;
	align-items: center;
}
.testimonials-section .test-card-body .quote i{
	font-size: 45px;
	color: var(--pink);
	margin-right: 20px;
}
.testimonials-section .test-card-body .quote h2{
	color: var(--heading-clr);
}
.testimonials-section .test-card-body p{
	margin: 10px 0px 15px;
	font-size: 14px;
	line-height: 1.5;
	color: var(--text-clr);
}
.testimonials-section .test-card-body .ratings{
	margin-top: 20px;
}
.testimonials-section .test-card-body .ratings i{
	font-size: 17px;
	color: var(--pink);
	cursor: pointer;
}
.uncheck{
	color: rgb(176, 176, 176) !important;

}
.testimonials-section .testimonial-card .profile{
	display: flex;
	align-items: center;
	margin-top: 25px;
}
.testimonials-section .profile .profile-image{
	width: 55px;
	height: 55px;
	border-radius: 50%;
	overflow: hidden;
	margin-right: 15px;
}
.testimonials-section .profile .profile-image img{
	width: 100%;
	height: 100%;
	border-radius: 50%;
	object-fit: contain;
}
.testimonials-section .profile .profile-desc{
	display: flex;
	flex-direction: column;
}
.testimonials-section .profile-desc span:nth-child(1){
	font-size: 24px;
	font-weight: bold;
	color: var(--pink);
}
.testimonials-section .profile-desc span:nth-child(2){
	font-size: 15px;
	color: grey;
}



@media (max-width:322px) {
    .clients-img{
        margin-top: 3rem;
    }
}

@media (max-width:500px) {
		.testimonials-container .testimonial-card{
			padding: 2px;
		}

}