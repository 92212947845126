.QAservices_head{
    background-image: linear-gradient(to left,rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(../../../public/images/applicaiton_migraiton.png);
    width: 100%;
    height: 400px;
    background-position: center;
    object-fit: cover;
    background-repeat: no-repeat;
    color: #fff;
    padding: 8rem 8%;
}



.QAservices_head h2{
    font-size: 3.8rem;
    
}

.QAservices_head p{
    font-size: 1.5rem;
    margin-top: 2rem;
    line-height: 2.7rem;
}

.QA-heading{
    margin: 0 8%;
	text-align:center;
}
.QA-heading h1{
	position: relative;
    padding:0;
	letter-spacing: 1px;
    margin:30px 0;
    font-size: 34px;
    font-weight: 700;  
    text-align:center;
}

.QA-heading h1:before{
	content: '';
    width: 60px;
    height: 3px;
    background: #f91942;
    position: absolute;
    left: 0px;
    bottom: -5px;
    right:0;  
    margin:0 auto;
}

.QA-heading p{
	color: rgba(61, 61, 61, 0.6);
	font-size: 1.4rem;
	margin: 5px;
	text-align: center;
}



.QAboxinner{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    margin: 2% 5%;
  }



  .QAitem {
    background: #fff;
    text-align: center;
    padding: 30px 20px;
    width: 340px;
    -webkit-box-shadow:0 0px 25px rgba(0, 0, 0, 0.07);
    box-shadow:0 0px 25px rgba(0, 0, 0, 0.07);
    border-radius: 20px;
    border:5px solid rgba(0, 0, 0, 0.07);
    margin-bottom: 30px;
    -webkit-transition: all .5s ease 0;
    transition: all .5s ease 0;
    transition: all 0.5s ease 0s;
}

.QAitem p{
  font-size:15px;
  line-height:26px;
}
.QAitem h6 {
  margin-bottom:20px;
  color:#2f2f2f;
  font-size: 15px;
}

.QAitem:hover{
    background:#f6375a;
    box-shadow:0 8px 20px 0px rgba(0, 0, 0, 0.2);
    -webkit-transition: all .5s ease 0;
    transition: all .5s ease 0;
    transition: all 0.5s ease 0s;
}
.QAitem:hover .QAitem, .QAitem:hover span.icon{
    background:#fff;
    border-radius:10px;

    -webkit-transition: all .5s ease 0;
    transition: all .5s ease 0;
    transition: all 0.5s ease 0s;
}
.QAitem:hover h6, .QAitem:hover p{
    color:#fff;
    -webkit-transition: all .5s ease 0;
    transition: all .5s ease 0;
    transition: all 0.5s ease 0s;
}
.QAitem .icon {
    font-size: 30px;
    margin-bottom:25px;
    color: #f91942;   
    width: 2.5em;
    height: 2.5em;
    line-height: 96px;
    border-radius: 50px;
    padding: 15px 20px;
}
.QAitem .feature_box_col_one{
    background:rgba(247, 198, 5, 0.20);
    color:#f91942
}
.QAitem .feature_box_col_two{
    background:rgba(255, 77, 28, 0.15);
    color:#f91942
}
.QAitem .feature_box_col_three{
    background:rgba(0, 147, 38, 0.15);
    color:#f91942;
    padding: 15px 26px;

}
.QAitem .feature_box_col_four{
    background:rgba(0, 108, 255, 0.15);
    color:#f91942;
    padding: 15px 25px;

}
.QAitem .feature_box_col_five{
    background:rgba(146, 39, 255, 0.15);
    color:#f91942;
    padding: 15px 22px;

}
.QAitem .feature_box_col_six{
    background:rgba(23, 39, 246, 0.15);
    color:#f91942;
    padding: 15px 20px;

}
.QAitem p{
    font-size:15px;
    line-height:26px;
}
.QAitem h6 {
    margin-bottom:20px;
    color:#2f2f2f;
}

@media screen and (max-width: 450px) {
    .QAservices_head{
        
        padding: 4rem 6%  53rem;
    }
}