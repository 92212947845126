/* .slider{
    height: 100px;
    margin: auto;
    position: relative;
    width: 90%;
    display: grid;
    place-items: center;
    overflow: hidden;
}

.slidetrack{
    display: flex;
    width: calc(250px * 7);
    animation: scroll 10s linear infinite;   

}

.slide{
    display: flex;
    align-items: center;
    padding: 15px;
    perspective: 100px;
    margin-left: 20px;
}

@keyframes scroll {
    0% {
        transform:  translateX(0);
    }

    100%{
        transform: translateX(calc(-250px * 7));
    }
}

img{
    width: 100%;
    transition: transform 1s;
}

img:hover{
    transform: translateZ(20px);
}  */

.techstacksection{
    margin-top: 4rem;
}

.slider .slidetrack{
    display: flex;  
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 7rem;
    row-gap: 2rem;
    padding: 1rem 1rem 4rem;
    border-radius: 2%;
   
}
   

.slide{
    /* background-color: rgb(255, 255, 255); */
    /* -webkit-box-shadow: -1px 3px 14px -3px rgba(0,0,0,0.75); */
    /* -moz-box-shadow: -1px 3px 14px -3px rgba(141, 139, 139, 0.75); */
    /* box-shadow: -1px 3px 14px -3px rgba(91, 91, 91, 0.66); */
    border-radius: 19%;
    padding: 0.6rem;
    transition: all .2s ease-in-out;
    margin-top: 2rem;
    text-align: center;
    width: 120px;

}

.slide img{
    height: 70px;
}

.slide p{
    font-size: 14px;
}

.slidetrack .slide:hover{
    transform: scale(1.2);
    transition: 1s;
}

