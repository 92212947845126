.homeblog{
    margin-top: 6rem;
}

.homeblog p:first-child{
    text-align: right;
    color: var(--pink);
    font-size: 1.4rem;
    margin: 0 6%;
    cursor: pointer;
}