.portfolios{
    margin: 12rem 10%;

}

.portfolio-container{
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 1rem;
    margin-top: 6rem;
    border-radius: 21px;
    background-color: rgba(255, 95, 3,0.1);
    padding: 3rem;
  
}

.portfolio-content h1{
    font-size: 3.5rem;
}

.portfolio-content p{
    font-size: 1.5rem;
    margin-top: 8px;
}

.portfolio-services p:not(:first-child){
        font-size: 15px;
        line-height: 16px;
}

.portfolio-services p:first-child{
    color: blue;
}


.portfolio-services{
    margin-top: 2rem;
}

.portfolio-image img{
    width: 460px;
    height: 250px;
    /* object-fit: contain; */
}