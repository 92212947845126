

.application_maintaince{
    background-image: linear-gradient(to left,rgba(110, 110, 110, 0.5), rgba(34, 34, 34, 0.5)),url(../../../public/images/application_maintainance_bg.png);
    width: 100%;
    height: 500px;
    background-position: center;
    color: #fff;
    /* text-align: center; */
    padding: 8rem 13%;
    transition: 1s;
}

.application_maintaince h1{
    font-size: 4rem;
}
.application_maintaince p:first-child{
    font-size: 1.8rem;
}

.application_maintaince p{
    font-size: 1.4rem;
    text-align: left;
    margin-top: 1rem;
}

.application_maintaince button {
  align-items: center;
  background-color: var(--purple);
  border: 0;
  border-radius: 100px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-flex;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 20px;
  max-width: 480px;
  min-height: 40px;
  min-width: 0px;
  overflow: hidden;
  padding: 0px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  touch-action: manipulation;
  transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  margin-top: 3rem;
}

.application_maintaince button:hover { 
  background-color: #2770cf;
  color: #ffffff;

}



 .timeline {
    display: flex;
    justify-content: center;
    background: var(--primary-color);
    margin-top: 4rem;
    padding: 20px;
  }
  
  /* Card container */
   .application_maintainance_container .card {
    position: relative;
    max-width: 400px;
   
  }
  
  /* setting padding based on even or odd */
   .application_maintainance_container .card:nth-child(odd) {
    padding: 30px 0 30px 30px;
  }
   .application_maintainance_container .card:nth-child(even) {
    padding: 30px 30px 30px 0;
  }
  /* Global ::before */
   .application_maintainance_container .card::before {
    content: "";
    position: absolute;
    width: 50%;
    border: solid orangered;
  }
  
  /* Setting the border of top, bottom, left */
   .application_maintainance_container .card:nth-child(odd)::before {
    left: 0px;
    top: -4.5px;
    bottom: -4.5px;
    border-width: 5px 0 5px 5px;
    border-radius: 50px 0 0 50px;
  }
  
  /* Setting the border of top, bottom, right */
   .application_maintainance_container .card:nth-child(even)::before {
    right: 0;
    top: 0;
    bottom: 0;
    border-width: 5px 5px 5px 0;
    border-radius: 0 50px 50px 0;
  }
  
  /* Removing the border if it is the first card */
   .application_maintainance_container .card:first-child::before {
    border-top: 0;
    border-top-left-radius: 0;
  }
  
  /* Removing the border if it is the last card  and it's odd */
   .application_maintainance_container .card:last-child:nth-child(odd)::before {
    border-bottom: 0;
    border-bottom-left-radius: 0;
  }
  
  /* Removing the border if it is the last card  and it's even */
   .application_maintainance_container .card:last-child:nth-child(even)::before {
    border-bottom: 0;
    border-bottom-right-radius: 0;
  }
  
  /* Information about the timeline */
   .application_maintainance_container .card_info {
    display: flex;
    flex-direction: column;
    background: #333;
    color: gray;
    border-radius: 10px;
    padding: 10px;
  }

   .application_maintainance_container .card_info p{
    font-size: 14px;
    padding: 1rem 5px;
  }
  
  /* Title of the card */
   .application_maintainance_container .title {
    color: orangered;
    position: relative;
    font-size: 20px;
  }
  
  /* Timeline dot  */
   .application_maintainance_container .title::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    background: white;
    border-radius: 999px;
    border: 3px solid orangered;
  }
  
  /* text right if the card is even  */
   .application_maintainance_container .card:nth-child(even) > .card_info > .title {
    text-align: right;
  }
  
  /* setting dot to the left if the card is odd */
   .application_maintainance_container .card:nth-child(odd) > .card_info > .title::before {
    left: -45px;
  }
  
.application_maintainance_container 
   .card:nth-child(even) > .card_info > .title::before {
    right: -45px;
  }




  .chooseus-section h4 {
    position: relative;
    padding:0;
    color:#f91942;
    line-height: 1;
    letter-spacing:0.3px;
    font-size: 34px;
    font-weight: 700;  
    text-align:center;
    text-transform:none;
    margin:30px 0;
  }
  .chooseus-section h4:before {
    content: '';
    width: 60px;
    height: 3px;
    background: #f91942;
    position: absolute;
    left: 0px;
    bottom: -10px;
    right:0;  
    margin:0 auto;
  }

  .chooseus-section h4 span {
    font-weight: 700;
    padding-bottom: 5px;
    color:#2f2f2f
  }

  .chooseus-section p{
    color:#818181;
    font-size:16px;
    line-height:28px;
    text-align:center;  
    margin: 0 8%;
  }


  .chooseusinner{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    margin: 2% 5%;
  }


  .application_maintainance_container  .item {
    background: #fff;
    text-align: center;
    padding: 30px 20px;
    width: 340px;
    -webkit-box-shadow:0 0px 25px rgba(0, 0, 0, 0.07);
    box-shadow:0 0px 25px rgba(0, 0, 0, 0.07);
    border-radius: 20px;
    border:5px solid rgba(0, 0, 0, 0.07);
    margin-bottom: 30px;
    -webkit-transition: all .5s ease 0;
    transition: all .5s ease 0;
    transition: all 0.5s ease 0s;
}

.application_maintainance_container  .item p{
  font-size:15px;
  line-height:26px;
}
.item h6 {
  margin-bottom:20px;
  color:#2f2f2f;
  font-size: 15px;
}

.application_maintainance_container .item:hover{
    background:#f6375a;
    box-shadow:0 8px 20px 0px rgba(0, 0, 0, 0.2);
    -webkit-transition: all .5s ease 0;
    transition: all .5s ease 0;
    transition: all 0.5s ease 0s;
}
.application_maintainance_container  .item:hover .item, .application_maintainance_container  .item:hover span.icon{
    background:#fff;
    border-radius:10px;

    -webkit-transition: all .5s ease 0;
    transition: all .5s ease 0;
    transition: all 0.5s ease 0s;
}
.application_maintainance_container .item:hover h6, .application_maintainance_container .item:hover p{
    color:#fff;
    -webkit-transition: all .5s ease 0;
    transition: all .5s ease 0;
    transition: all 0.5s ease 0s;
}
.application_maintainance_container .item .icon {
    font-size: 30px;
    margin-bottom:25px;
    color: #f91942;   
    width: 2.5em;
    height: 2.5em;
    line-height: 96px;
    border-radius: 50px;
    padding: 15px 20px;
}
.application_maintainance_container  .item .feature_box_col_one{
    background:rgba(247, 198, 5, 0.20);
    color:#f91942
}
.application_maintainance_container  .item .feature_box_col_two{
    background:rgba(255, 77, 28, 0.15);
    color:#f91942
}
.application_maintainance_container  .item .feature_box_col_three{
    background:rgba(0, 147, 38, 0.15);
    color:#f91942;
    padding: 15px 26px;

}
.item .feature_box_col_four{
    background:rgba(0, 108, 255, 0.15);
    color:#f91942;
    padding: 15px 25px;

}
.application_maintainance_container  .item .feature_box_col_five{
    background:rgba(146, 39, 255, 0.15);
    color:#f91942;
    padding: 15px 22px;

}
.application_maintainance_container  .item .feature_box_col_six{
    background:rgba(23, 39, 246, 0.15);
    color:#f91942;
    padding: 15px 20px;

}
.application_maintainance_container  .item p{
    font-size:15px;
    line-height:26px;
}
.application_maintainance_container  .item h6 {
    margin-bottom:20px;
    color:#2f2f2f;
}


.technologies-section{
  padding: 2.3% 4%;
  /* background-color: rgb(145, 84, 65); */
  position: relative;
}

.patterns{
  position: absolute;
  width: 190px;
  height: 50%;
  border-radius: 50%;
  left: 0;
  margin-top: -70px;
  margin-left: -40px;
  z-index: -1;

}

.technologies-section h4{
  font-size: 3rem;
}

.technologies-section .box-container{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 2.5rem;
  row-gap: 2.5rem;
  margin-top: 2.4rem;

}

.technologies-section .box-inner{
  text-align: center;
}

.technologies-section .box-container .box{
  display: flex;
  justify-content: center;
  font-size: 8rem;
  background-color: rgb(233, 231, 231);
  color: var(--pink);
  padding: 3rem 4rem;
  border-radius: 21px;
  width: 15rem;
}

.box-container p{
  font-size: 1.7rem;
  font-weight: bold;
  margin-top: 1rem;
}


@media screen and (max-width: 450px) {
    
  .application_maintaince{

    padding: 2rem 6% 55rem;
    transition: 1s;
  }
}