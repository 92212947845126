
#background-video{
   
    object-fit: cover;
    top: 0;
    width: 100%;
    height: 600px;
    position: absolute;
    z-index: -1;
}

.homesectioncontainer .home{
    padding-top: 1rem;
    padding-bottom: 4rem;
    /* display: flex;
    justify-content: center; */
}

.homesectioncontainer section{
    min-height: 100%;
    padding: 0 9%;
    
}

.homesectioncontainer .image img{
    width:40vw;
    visibility: hidden;
    /* animation: float 3s linear infinite; */
}

.homesectioncontainer .content{
  margin-top: 2rem;
}

.homesectioncontainer .content h3{
    font-family: 'Catamaran', sans-serif;
    font-size: 5.2rem;
    color:#ffffff;
    /* text-transform: uppercase; */
    /* margin-top: 9rem; */
}
.tech{
    font-family: 'Catamaran', sans-serif;
    font-size: 4.7rem !important;
    color:#ffffff !important;
}

.homesectioncontainer .content span{
    color:var(--pink);
    /* text-transform: uppercase;   */
    font-size: 3.8rem;
    font-weight: bold;
}

.homesectioncontainer .content p{
    font-size: 1.7rem;
    color:#f1f1f1;
    padding:1rem 0;
    text-wrap: wrap;
}


.homesectioncontainer .designrushimg img{
    /* width: 6vw; */
    height: 5rem;
} 

.homesectioncontainer .goodfirmsimg img{
    height: 6rem;
    /* width: 8vw;  */
}
.homesectioncontainer .clutchimg img{
    height: 4rem;
}

.homesectioncontainer .wearein{
    position: relative;
}

.homesectioncontainer .wearein p{
    font-size: 4rem;
    color: var(--pink) !important;
}
 
.homesectioncontainer .inner{
    display: inline-block;
    text-align: center;
} 

.homesectioncontainer .line{
    background-color: red;
    height: 2px;
    width: 60px;
    display: flex;
    justify-content: center;
    margin-left: 70px;
}

.homesectioncontainer .wearein{

    text-align: center;
     -webkit-box-shadow: -1px 3px 14px -3px rgba(0,0,0,0.75);
     -moz-box-shadow: -1px 3px 14px -3px rgba(141, 139, 139, 0.75);
     box-shadow: -1px 3px 14px -3px rgba(91, 91, 91, 0.66);
     border-radius: 2%;
     transition: all .1s linear;
     position: relative;
}



.homesectioncontainer .homeimages{
    /* width: 60%; */
    display: flex;
    align-items: center;
    /* justify-content: center; */
    flex-wrap: wrap;
    /* row-gap: 2rem; */
    column-gap: 5rem;
    padding: 3rem 0;
}

@media (max-width:1200px){
    .homesectioncontainer .home{
        padding-top: 1rem;
        padding-bottom: 12rem;
    }
}

@media (max-width:768px){

    /* .homesectioncontainer .home{
        flex-flow: column-reverse;
    } */

    /* .homesectioncontainer .image img{
        width:100%;
    } */

    .homesectioncontainer .home{
        padding-top: 1rem;
        padding-bottom: 19rem;
    }
    .homesectioncontainer .content h3{
        font-size: 3.6rem;
    }

    .homesectioncontainer .content p{
        font-size: 1.5rem;
    }

}

@media (max-width:480px){


    .homesectioncontainer .home{
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

}