
.application_migration_container{

    /* margin: 8rem 8%; */
    
}

.application_migration_head{
    background-image: linear-gradient(to left,rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(../../../public/images/applicaiton_migraiton.png);
    width: 100%;
    height: 500px;
    background-position: center;
    object-fit: cover;
    background-repeat: no-repeat;
    color: #fff;
    padding: 8rem 8%;
}

.application_migration_head h2{
    font-size: 3.8rem;
    
}

.application_migration_head p{
    font-size: 1.5rem;
    margin-top: 2rem;
    line-height: 2.7rem;
}

.services_heading{
        position: relative;
        padding:0;
        line-height: 1;
        letter-spacing:0.3px;
        font-size: 34px;
        font-weight: 700;  
        text-align:center;
        margin:30px 0;
    }

.services_heading:before {
    content: '';
    width: 60px;
    height: 3px;
    background: #f91942;
    position: absolute;
    left: 0px;
    bottom: -10px;
    right:0;  
    margin:0 auto;
  }

.application_migration_container .our_services{
    margin: 2% 5%;
    display: flex;
    justify-content: center;
    row-gap: 2rem;
    flex-wrap: wrap;
}

.application_migration_container .our_services  .term {
    display: flex;
    /* flex-wrap: wrap; */
    column-gap: 2rem;
    width: 460px;
}

.application_migration_container .our_services  .term img{
    width: 25%;
    height: 60%;
   
}

.term_content{
    width: 230px;
}

.term_content h3{
    font-size: 1.8rem;
}

.term_content p{
    font-size: 1.2rem;
    color:#6a6969;

}

.application_migration_container .para{
    margin: 0 8%;
    font-size: 1.6rem;
    color:#6a6969;
    text-align: center;

}


@media screen and (max-width: 450px) {
    
    .application_migration_head{
  
      padding: 5rem 6% 65rem;
      transition: 1s;
    }
  }