
.backtotopbtn{
    position: fixed;
    bottom: 50px;
    right: 50px;
    height: 40px;
    width: 40px;
    font-size: 50px;
    border-radius: 50%;
    background: var(--pink);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    cursor: pointer;
}

@media (max-width:575px) {
    .backtotopbtn{
        bottom: 20px;
        right: 20px;
        height: 40px;
        width: 40px;
        
    }
}