

.application_modernization_head{
    background-image: linear-gradient(to left,rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(../../../public/images/applicaiton_migraiton.png);
    width: 100%;
    height: 400px;
    background-position: center;
    object-fit: cover;
    background-repeat: no-repeat;
    color: #fff;
    padding: 8rem 8%;
}


.application_modernization_head h2{
    font-size: 3.8rem;
    
}

.application_modernization_head p{
    font-size: 1.5rem;
    margin-top: 2rem;
    line-height: 2.7rem;
}






.services{
	width:100%;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
}
.s-heading{
	text-align:center;
}
.s-heading h1{
	position: relative;
    padding:0;
	letter-spacing: 1px;
    margin:30px 0;
    font-size: 34px;
    font-weight: 700;  
    text-align:center;
}

.s-heading h1:before{
	content: '';
    width: 60px;
    height: 3px;
    background: #f91942;
    position: absolute;
    left: 0px;
    bottom: -5px;
    right:0;  
    margin:0 auto;
}

.s-heading p{
	color: rgba(61, 61, 61, 0.6);
	font-size: 1.4rem;
	margin: 5px;
	text-align: center;
}
.s-box-container{
	width:100%;
	display: flex;
    flex-wrap: wrap;
	justify-content: center;
	align-items: center;
}
.s-box{
	display:flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
	width:300px;
	padding: 14px 25px;
	height: 300px;
	box-sizing: border-box;
	margin: 5px 30px;
	position: relative;
	/* box-shadow: 2px 2px 30px rgba(0,0,0,0.08); */

}
.s-box img{
	height: 75px;
}
.s-box h1{
	color:#1c1c1c;
	letter-spacing: 1px;
	font-size: 1.5rem;
	margin: 12px 0;
    text-align: center;
	
}
.s-box p{
	color: rgba(61, 61, 61, 0.6);
	font-size: 1.3rem;
	text-align: center;
}

.bar{
	width: 100px;
	height: 6px;
	position: absolute;
	left: 50%;
	top: 0%;
	transform: translateX(-50%);
	background-color: var(--pink); 
	border-radius: 0px 0px 10px 10px;
	display: none;
	animation: bar 0.5s;
}
.s-box:hover{
	box-shadow: 2px 2px 30px rgba(0,0,0,0.08);
	transition: all ease 0.3s;
}

.s-box:hover .bar{
	display: block;
}
@keyframes bar{
	0%{
		width:0px;
	}
	100%{
		width:100px;
	}
}
@media(max-width:1050px){
	.s-box-container{
		flex-wrap: wrap;
		
	}	
	.services{
		height: auto;
	}
	.s-heading{
		margin: 15px;
	}
	
	.s-box{
        
		flex-grow: 1;
        height: 250px;
    
    }
}


@media(max-width:450px){
	.application_modernization_head{
		
		padding: 3rem 6% 53rem;
	}
}