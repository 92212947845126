.solutions {
    margin: 1rem 5% 6rem;
    /* width: 100%; */
}

.solutions .section-heading{
    font-size: 3.8rem;
}

.solutions .row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.solutions .column {
    width: 450px;
    padding: 0 1em 1em 1em;
    text-align: center;
    margin-top: 1rem;
}

.solutions .card {
    width: 100%;
    height: 100%;
    padding: 2em 1.5em;
    background: linear-gradient(#ffffff 50%, var(--purple) 50%);
    background-size: 100% 200%;
    background-position: 0 2.5%;
    border-radius: 5px;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    transition: 0.5s;
}

.solutions .card h3 {
    font-size: 20px;
    font-weight: 600;
    color: #1f194c;
    margin: 1em 0;
}

.solutions .card p {
    color: #575a7b;
    font-size: 15px;
    line-height: 1.6;
    letter-spacing: 0.03em;
}

.solutions .icon-wrapper {
    background-color: var(--purple);
    position: relative;
    margin: auto;
    font-size: 30px;
    height: 2.5em;
    width: 2.5em;
    color: #ffffff;
    border-radius: 50%;
    display: grid;
    place-items: center;
    transition: 0.5s;
}

.solutions .card:hover {
    background-position: 0 100%;
}

.solutions .card:hover .icon-wrapper {
    background-color: #ffffff;
    color: var(--purple);
}

.solutions .card:hover h3 {
    color: #ffffff;
}

.solutions .card:hover p {
    color: #f0f0f0;
}

@media screen and (min-width: 768px) {

.solutions .column {
    flex: 0 50%;
    max-width: 50%;
}
}
@media screen and (min-width: 992px) {

.solutions .column {
    flex: 0 0 33.33%;
    max-width: 33.33%;
}
}