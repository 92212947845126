.blog-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 12rem 2rem;
    flex-wrap: wrap;
}

.blog-box{
    width: 350px;
    background-color: #ffffff;
    border: 1px solid #ececec;
    margin: 20px;
}

.blog-img{
    width: 100%;
    height: 33vh;
}


.blog-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center ;
}

.blog-text{
    padding: 30px;
    display: flex;
    flex-direction: column;
}

.blog-text span{
    color: var(--pink);
    font-weight: 600;
    font-size: 1.2rem;
}

.blog-text .blog-title{
    font-size: 1.8rem;
    font-weight: 500;
    color: #272727;
}

.blog-text .blog-title:hover{
    color: var(--pink);
    transform: all ease 0.3s;
}

.blog-text p{
    color: #9b9b9b;
    font-size: 1.3rem;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 6px 0px;
}


.blog-text a{
    color: #0f0f0f;
    font-size: 1.4rem;
}

.blog-text a:hover{
    color: var(--pink);
    transition: all ease 0.3s;
}