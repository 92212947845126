
.privacy{
    margin: 11rem 6rem;
}

.privacy p{
    font-size: 1.8rem;
}

.privacy ul{
    font-size: 2rem;
    margin: 0 6rem;
}