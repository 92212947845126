.info-h{
    background-color: #2B2F3E;
    color: #fff;
    text-align: center;
    font-size: 16px;
    padding-top: 2rem;
    margin-top: 4rem;
}

.info-bg
{
    background-color: #2B2F3E;
    color: #fff;
    display: flex;
    column-gap: 3rem;
    padding: 38px 69px;
    position: relative;
    box-sizing: border-box;
    overflow-x: hidden;
}


.info-head{
    color: #fff;

}

.info-bg i{
    font-size: 28px;
    padding: 5px 0px;
    display: flex;
    justify-content: center;
}


.info-bg i:first-child{
    color: orange;
}



.info-bg h3{
    font-size: 22px;
    padding: 12px 0px;
    text-align: center;
}

.info-bg p{
    color: rgb(187, 187, 187);
    font-size: 17px;
    text-align: center;
}



@media (max-width:910px){
    .info-bg{
        flex-direction: column;
        row-gap: 3rem;
    }   
}
